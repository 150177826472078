body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.beta {
  font-size: 60%;
}

.unselectable {
  user-select: none;
}

.dropdown-link {
  color: black !important;
}
.bd-masthead {
  padding: 3rem 0;
  background: linear-gradient(165deg, #f7f5fb 50%, #fff 50%);
}

.text-md-start {
  text-align: left !important;
}

.masthead-followup-icon {
  padding: 0.75rem;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.01));
  border-radius: 0.75rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.green-text {
  color: #009688;
}

.red-text {
  color: #E91E63;
}
